import { Spacing, Typography, Colors } from "../../styles";

const styles = {
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginTop: Spacing.large,
  },
};

export default styles;
