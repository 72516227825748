import { Spacing, Typography, Colors } from "../../styles";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    //backgroundColor: Colors.dark4,
    width: "100%",
    justifyContent: "space-between",
    marginTop: 60,
  },
};

export default styles;
