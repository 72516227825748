import { Spacing, Typography, Colors } from "../../styles";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: Spacing.larger,
  },
  emailContainer: {
    display: "flex",
    alignItems: "center",
    height: 50,
    width: "100%",
    backgroundColor: "#ffffff",
    border: "solid",
    borderWidth: 1,
    borderColor: Colors.dark4,
    borderRadius: 20,
  },
  inputField: {
    ...Typography.p1,
    width: "65%",
    marginLeft: Spacing.small,
    border: "none",
    outline: "none",
  },
  submitEmailButton: {
    position: "relative",
    marginLeft: "auto",
    marginRight: 10,
    paddingLeft: Spacing.base,
    paddingRight: Spacing.base,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: 120,
    height: 40,
    backgroundColor: "#f86a6a",
    borderRadius: 15,

    ...Typography.p2,
    ...Typography.heavy,
    color: "#FFFFFF",
    border: "none",
  },
};

export default styles;
