import { Spacing, Typography, Colors } from "./styles";

const styles = {
  container: {
    //backgroundColor: Colors.dark4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  darkContainer: {
    backgroundColor: Colors.dark1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 40,
    paddingLeft: "5%",
    paddingRight: "5%",
  },
};

export default styles;
