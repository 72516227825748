import React from "react";
import styles from "./styles.js";
import { Typography, Spacing, Colors, mobileWidth } from "../../styles";
//import { Chrono } from "react-chrono";
import computerEmoji from "../../assets/images/computerEmoji.png";
import Classi_design from "../../assets/images/Classi_design.png";
import Arctype_design from "../../assets/images/Arctype_design.png";
import Appclip_design from "../../assets/images/appclip.png";
import PortfolioCard from "../PortfolioCard";
import { useMediaQuery } from "react-responsive";

const Portfolio = () => {
  const isMobile = useMediaQuery({ maxWidth: mobileWidth });
  return (
    <div style={styles.container} id="portfolio">
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={computerEmoji}
          alt="computer"
          style={{ height: Spacing.largest, marginRight: Spacing.small }}
        />
        <div
          style={
            isMobile ? { ...Typography.h2, fontSize: 40 } : { ...Typography.h1 }
          }
        >
          Work Portfolio
        </div>
      </div>
      <div style={{ marginTop: Spacing.largest }}>
        <PortfolioCard
          image={Arctype_design}
          title="Growth @ Arctype"
          subtitle="the collaborative SQL editor for teams"
          body={
            "Arctype is an early-stage developer tools startup. \n\n\
I joined the company in December 2020 to own all growth metrics.\n\n"
          }
          website="https://www.arctype.com"
        />
        <PortfolioCard
          image={Classi_design}
          title="Founder/CEO @ Classi"
          subtitle="the marketplace for live fitness classes with friends"
          body={
            "Home workouts suck. Classi started when I was Facetiming a friend in NYC and I learned about these \
great, new online live classes that her old studio started doing after they closed their phyiscal locations. \n\n\
I went to sign up for some classes myself and realized that there was no centralized platform for finding live fitness classes. \
I recruited some friends, and in April 2020 we started building the first platform \
to discover, register, and participate in live fitness classes. \n\n\
In < 3 months we went from an idea to designs to a prototype built in React Native with a GraphQL backend. \
Development is currently paused due to resource constraints, but send me a message if you are interested in learning more and I would be happy to chat!\n\n"
          }
          website="https://www.classi.live"
          second_link="https://youtu.be/Iu62LxEFUlE"
        />
        <PortfolioCard
          image={Appclip_design}
          title="Product Manager @ the Capital One Lab"
          subtitle="innovation lab focused on the intersection of emerging technology and finance"
          body={
            "In August 2019, I joined Capital One as 1 of 10 associates in their inaugral APM cohort. \
During my time at Capital One, I worked with multiple development teams of engineers and designers to ship new experiences on iOS, Android, and Web. \n\n\
Project 2: Created a prototype of the first iOS App Clip. Details confidential. \n\n\
Project 1: Created an automatic savings feature on mobile and web. Details confidential. \n\n\
I feel incredibly lucky that I stumbled onto Capital One when I was a sophomore in college and decided to send in an internship application. \
Over my 3+ years at Capital One, its bar for rigorous analysis, \
comprehensive strategy, and articulate storytelling have become my not-so-secret weapons. I’m confident that these are skills that \
I will continue to rely on (and refine) in every stage of my career.\n\n"
          }
          website="https://www.thecapitalonelab.com"
        />
      </div>
    </div>
  );
};

export default Portfolio;

/*
  const items = [
    {
      title: "November 2020",
    },
    {
      title: "March 2020",
    },
    {
      title: "August 2019",
    },
  ];
<div
style={{
  width: "70%",
  height: "1000px",
}}
>
<Chrono
  items={items}
  mode="VERTICAL"
  hideControls={true}
  theme={{ primary: Colors.dark4, secondary: Colors.accent1 }}
  allowDynamicUpdate={true}
  cardHeight="100"
  style={Typography.h3}
>
  <div>
    <div style={{ ...Typography.h3, marginBottom: Spacing.smaller }}>
      Arctype growth consultant
    </div>
    <div style={{ ...Typography.p1, color: Colors.dark3 }}>
      Arctype is a collaborative SQL editor for developers. I was
      brought on as a growth consultant for the team to drive user
      acquisition and engagement. I created the growth strategy and am
      leading marketing efforts across all channels, including content,
      SEO, developer relations, and more.
    </div>
  </div>
  <div>
    <div style={{ ...Typography.h3, marginBottom: Spacing.smaller }}>
      Classi
    </div>
    <div style={{ ...Typography.p1, color: Colors.dark3 }}>
    </div>
  </div>
  <div>
    <div style={{ ...Typography.h3, marginBottom: Spacing.smaller }}>
      Capital One
    </div>
    <div style={{ ...Typography.p1, color: Colors.dark3 }}>
    </div>
  </div>
</Chrono>
</div>
*/
