import "../assets/fonts/circularFont.css";
import * as Spacing from "./spacing";
import * as Colors from "./colors";

export const h1 = {
  fontFamily: "Circular Std",
  fontSize: 60,
  color: Colors.dark1,
};

export const h2 = {
  fontFamily: "Circular Std",
  fontSize: Spacing.larger,
  color: Colors.dark1,
};

export const h3 = {
  fontFamily: "Circular Std",
  fontSize: Spacing.large,
  color: Colors.dark1,
};

export const p1 = {
  fontFamily: "Circular Std Book",
  fontSize: Spacing.base,
  color: Colors.dark1,
};

export const p2 = {
  fontFamily: "Circular Std Book",
  fontSize: Spacing.small,
  color: Colors.dark1,
};

export const heavy = {
  fontWeight: "900",
};

export const normal = {
  fontWeight: "normal",
};

export const light = {
  fontFamily: "Circular Std Book",
};
