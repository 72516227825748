import React from "react";
import styles from "./styles.js";
import { Typography, Spacing, Colors, mobileWidth } from "../../styles";
import { useMediaQuery } from "react-responsive";

const PortfolioCard = (props) => {
  const isMobile = useMediaQuery({ maxWidth: mobileWidth });

  return (
    <div>
      <div style={isMobile ? { color: "blue" } : styles.container}>
        <img
          src={props.image}
          style={
            isMobile
              ? { width: 350, marginBottom: Spacing.larger }
              : { width: 500, marginRight: Spacing.largest }
          }
          alt="Arctype website screenshot"
        />
        <div style={styles.textBlock}>
          <div style={{ ...Typography.h2, ...Typography.heavy }}>
            {props.title}
          </div>
          <div style={{ ...Typography.p1, color: Colors.dark3 }}>
            {props.subtitle}
          </div>
          <div
            style={{
              ...Typography.p1,
              color: Colors.dark2,
              marginTop: Spacing.base,
              whiteSpace: "pre-wrap",
            }}
          >
            {props.body}
            <a
              href={props.website}
              target="_blank"
              style={{ color: Colors.accent1 }}
            >
              {props.website}
            </a>
            {props.second_link ? (
              <a
                href={props.second_link}
                target="_blank"
                style={{ color: Colors.accent1 }}
              >
                {"\n1 minute explainer video"}
              </a>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
      <div
        style={
          isMobile
            ? {
                backgroundColor: Colors.dark5,
                height: 3,
                marginTop: 40,
                marginBottom: 40,
              }
            : {
                backgroundColor: Colors.dark5,
                height: 3,
                marginTop: 80,
                marginBottom: 80,
              }
        }
      />
    </div>
  );
};

export default PortfolioCard;
