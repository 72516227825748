import React from "react";
import styles from "./styles.js";
import { Typography, Spacing, Colors, mobileWidth } from "../../styles";
import rocketImg from "../../assets/images/rocketImg.png";
//import Arctype from "../../assets/images/Arctype.png";
//import Classi from "../../assets/images/Classi.png";
//import Labs from "../../assets/images/Labs.png";
import Derek from "../../assets/images/derek.png";
import { useMediaQuery } from "react-responsive";
//import SubmitEmail from "../SubmitEmail";

const Intro = () => {
  const isMobile = useMediaQuery({ maxWidth: mobileWidth });
  var textStyle = { ...styles.subText, paddingRight: Spacing.base };

  return (
    <div style={{ marginTop: 80 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: Spacing.largest,
        }}
      >
        <img
          src={rocketImg}
          alt="rocket"
          style={{ height: Spacing.largest, marginRight: Spacing.small }}
        />
        <div
          style={
            isMobile
              ? { ...Typography.h2, fontSize: 40, color: Colors.dark5 }
              : { ...Typography.h1, color: Colors.dark5 }
          }
        >
          Building something
        </div>
      </div>
      <div style={styles.container}>
        <div>
          <div style={{ ...Typography.h2, color: Colors.dark4 }}>
            Hey, I'm Derek
          </div>
          <div
            style={
              isMobile
                ? { width: "100%" }
                : {
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                  }
            }
          >
            <img
              src={Derek}
              alt="profile"
              style={{ marginTop: Spacing.base, width: 200 }}
            />
            <div style={isMobile ? { width: "100%" } : { width: "40%" }}>
              <div style={textStyle}>
                I'm currently working on growth at{" "}
                <a style={{ color: "white" }} href="https://arctype.com">
                  Arctype
                </a>{" "}
                to manage top funnel acquisition channels, product activation,
                and virality.
              </div>
              <div style={{ ...styles.subText, whiteSpace: "pre-wrap" }}>
                {
                  "Prior to my current role I was in the first APM cohort at Capital One.\n\n\
I graduated from UMD in 2019 with degrees in computer science and finance."
                }
              </div>
              {/*isMobile && (textStyle.color = "green")*/}
            </div>
          </div>
          <div
            style={
              isMobile
                ? {
                    ...styles.ctaBorder,
                  }
                : { ...styles.ctaBorder, width: "55%" }
            }
          >
            <div
              style={{
                ...Typography.h3,
                ...Typography.light,
                color: Colors.dark5,
                display: "flex",
              }}
            >
              Join the Shipped newsletter
            </div>
            <div
              style={{
                ...Typography.p1,
                color: Colors.dark5,
                marginTop: Spacing.smaller,
                display: "flex",
              }}
            >
              I share personal essays on building and growing products.
            </div>
            <iframe
              src="https://shipped.substack.com/embed"
              height="100"
              style={{
                paddingTop: Spacing.small,
                alignSelf: "flex-start",
              }}
              frameBorder="0"
              scrolling="no"
              title="substack"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;

/*
<div>
<img
  src={Arctype}
  alt="Arctype"
  style={{ width: "25%", position: "absolute", top: 250, left: "58%" }}
/>
<img
  src={Classi}
  alt="Classi"
  style={{ width: "27%", position: "absolute", top: 340, left: "65%" }}
/>
<img
  src={Labs}
  alt="Labs"
  style={{ width: "30%", position: "absolute", top: 490, left: "60%" }}
/>
</div>
*/
