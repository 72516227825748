import { Spacing, Typography, Colors } from "../../styles";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    //backgroundColor: Colors.dark4,
  },
  textBlock: {
    display: "flex",
    flexDirection: "column",
    //marginTop: Spacing.base,
    //backgroundColor: Colors.dark3,
  },
};

export default styles;
