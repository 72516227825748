import mixpanel from "mixpanel-browser";

if (process.env.NODE_ENV === "production") {
  mixpanel.init("9795684db6fbf4d5d4434de2c441b903");
} else {
  mixpanel.init("9795684db6fbf4d5d4434de2c441b903");
}

let actions = {
  pageView: (props) => {
    mixpanel.track("pageView", props);
  },
  emailSubmitted: (props) => {
    mixpanel.track("emailSubmited", props);
  },
  emailSubmittedFail: (props) => {
    mixpanel.track("emailSubmitedFail", props);
  },
  clickBlog: (props) => {
    mixpanel.track("clickBlog", { ...props });
  },
};

export let Mixpanel = actions;
