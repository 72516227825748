import { Spacing, Typography, Colors } from "../../styles";

const styles = {
  container: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  subText: {
    ...Typography.p1,
    marginTop: Spacing.large,
    color: Colors.dark3,
  },
  ctaBorder: {
    display: "flex",
    flexDirection: "column",
    border: "solid",
    borderWidth: 1,
    borderColor: Colors.dark3,
    borderRadius: 10,
    padding: Spacing.base,
    paddingBottom: 0,
    marginTop: Spacing.largest,
    marginBottom: Spacing.base,
  },
};

export default styles;
