import styles from "./AppStyles.js";
import { Header, Intro, Portfolio, Mixpanel } from "./components";
import { Spacing, Typography, Colors, mobileWidth } from "./styles";
import { Link } from "react-scroll";
import { useMediaQuery } from "react-responsive";

function App() {
  const isMobile = useMediaQuery({ maxWidth: mobileWidth });
  return (
    <div>
      <div
        style={
          isMobile
            ? styles.darkContainer
            : {
                ...styles.darkContainer,
                paddingLeft: "12%",
                paddingRight: "12%",
              }
        }
      >
        {Mixpanel.pageView()}
        <Header />
        <Intro />
      </div>
      <div
        style={
          isMobile
            ? styles.container
            : { ...styles.container, paddingLeft: "12%", paddingRight: "12%" }
        }
      >
        <Portfolio />
      </div>
    </div>
  );
}

export default App;
