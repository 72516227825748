import React from "react";
import styles from "./styles.js";
import { Typography, Spacing, Colors } from "../../styles";
import { Link } from "react-scroll";

const Header = () => {
  return (
    <nav style={styles.container}>
      <div style={{ ...Typography.h3, color: Colors.dark6 }}>Derek Xiao</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          href="#portfolio"
          style={{
            ...Typography.p1,
            color: Colors.dark5,
            textDecoration: "none",
          }}
        >
          Portfolio
        </a>
        <a
          href="https://shipped.substack.com"
          target="_blank"
          style={{
            ...Typography.p1,
            color: Colors.dark5,
            textDecoration: "none",
            marginLeft: Spacing.large,
          }}
        >
          Blog
        </a>
      </div>
    </nav>
  );
};

export default Header;
