export const dark1 = "#232735";

export const dark2 = "#505565";

export const dark3 = "#8B90A0";

export const dark4 = "#D3D4D8";

export const dark5 = "#F9FAFC";

export const dark6 = "#F6F8FB";

export const accent1 = "#F86A6A";
